.login-form-wrapper {
    height:100%;
    background: rgb(0,0,0);
    background: linear-gradient(185deg, rgba(0,0,0,1) 0%, rgba(98,98,98,1) 97%);
}

.login-form{
    width: 550px;
    padding: 50px 50px 50px 50px;
    background-color: white;
    box-shadow: 7px 6px 17px 1px #80808054;
}

.login-form h3{
    text-align: center;
    margin-bottom: 30px;
}

.login-button, .restart-button{
    height:50px;
    width:100%;
}

.restart-button a {
    text-decoration: none;
    color:white;
    justify-content: center;
}

.new-password-info {
    text-align: center;
    color: green;
    font-size: 15px;
}

.form-group.new-pass{
    text-align:center
}

.reset-pass-button{
    text-align:end;
    color:black;
    justify-content: flex-end;
}