.diaries-wrapper {
    margin:24px;
    padding:24px;
    background:white;
    border-right: 3px solid #68bb73;
}


.diaries-search-wrapper{
    /* background: #68bb73; */
    height: 100px;
}
.diary-elements-wrapper {
    padding: 24px 0px 24px 0px;
}

.diary-element{
    padding:24px;
    box-shadow: 3px 3px 6px -1px #bfbfbf;
    min-height: 150px;
    margin-bottom: 24px;
    background: #fbfbfb;
}

.red-diary{
    border-left:6px solid #ff6565;
}

.green-diary{
    border-left: 6px solid #81d453;
}

.blue-diary{
    border-left: 6px solid #5ecbf1;
}

.orange-diary{
    border-left: 6px solid #f9c251;
}

.diary-element:hover{
    cursor:pointer;
    background: #68bb733d;
}

.diary-element-date {
    width:150px;
    margin-bottom: 24px;
}

.diary-element-date span:first-child{
    font-size: 14px;
    color: #151515;
    font-weight: 600;
}

.diary-element-date span:last-child{
    font-size: 22px;
    font-weight: 300;
    color: #8a8a8a;
    letter-spacing: 2px;
}


.diary-element label {
    color: #353535;
    font-size: 17px;
    font-weight: 700;
}

.diary-element p {
    margin-bottom: 0;
    font-size: 15px;
}

.feeling-element {
    margin-right:48px;
}

.diary-user {
    margin-right: 24px;
    width:300px;
}

.diary-user label, .diary-date label {
    color: #4a4a4a;
}

.diary-date-picker-wrapper{
    margin-top: 6px;
}

.diary-date-picker-wrapper .react-date-picker{
    height:37px !important;
}

.diary-date-picker-wrapper .react-date-picker__wrapper{
    border:none;
    border-bottom: 1px solid grey;
}