.admin-sidebar-wrapper{
    position:fixed;
    width: 250px;
    background: #252525;
}

.admin-sidebar-wrapper nav span{
    padding-left:10px;
}
.admin-sidebar-wrapper nav a span{
    color:white;
}

.admin-sidebar-wrapper nav  {
    margin-top:20px;
}

.admin-sidebar-wrapper nav a {
    margin-bottom:10px;
}

.sidebar-logo{
    width: 100%;
    height: 80px;
    background: #68bb73;
}

.sidebar-text {
    font-weight: 700;
    color: #525252;
    font-size: 19px;
}

.nav-menu .menu-row{
    height:55px;
}

.nav-menu .menu-row .menu-row-icon{
    width: 80px;
}

.nav-menu .menu-row:hover{
    background: #5a5a5a;
    cursor: pointer;
}

.nav-menu .menu-row .menu-row-name{
    color: white;
}

.powered-by-sidebar{
    position:absolute;
    bottom:24px;
    right: 24px
}