.list-wrapper {
    margin: 0px 24px 24px 24px;
}

.list-card-wrapper{
    padding: 12px 24px 12px 24px;
    margin-bottom: 5px;
    width: 100%;
    min-height: 70px;
    border-left: 3px solid #68bb73;
    border-right: 1px solid #e8e8e8;
    background: white;
    border-bottom: 1px solid #b9b9b9;
    cursor:pointer;
}

.list-card-wrapper.is-admin{
    border-left: 5px solid red;
}

.list-card-wrapper:hover{
    background: #ebffe1;
    border-left: 3px solid #509827;
}

.list-card-wrapper.is-admin:hover{
    background: #ebffe1;
    border-left: 5px solid red;
}

.list-card-wrapper.no-active:hover{
    background: #ff9b9b;
    border-left: 3px solid red;
}

.list-card-wrapper a{
    text-decoration: none;
}

.list-card-wrapper.no-active{
    opacity: 0.3;
}

.parameters-list-wrapper {
    height: 80px;
}

.list-search-wrapper{
    max-width: 450px;
    position: relative;
}

.list-search-wrapper input{
    height: 50px;
    margin-bottom: 5px;
    padding: 0 !important;
    font-size: 20px !important;
}

.search-icon {
    position: absolute;
    right: 0;
    font-size: 21px;
    color: #252525;
}

.list-wrapper .card-section .card-label {
    font-size: 13px;
    font-weight: bold;
    color: #232323;
}

.list-wrapper .card-section .card-label-value {
    font-size: 15px;
    color: #232323;
}

.list-wrapper .card-section{
    margin-right: 24px; 
}

.no-element-wrapper{
    text-transform: uppercase;
    color: #909090;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 2px;
}