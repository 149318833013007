

html, body, #root, #root>div {
  overflow-x: auto;
    background: #f3f6ff;
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
}

.btn{
    border-radius: 0px;
}

label {
    margin-bottom: 0px !important;
}

.form-group textarea {
    font-size: 14px;
    border-radius: 0;
    padding-left: 0px !important;
    border: none;
    border-bottom: 1px solid #777777 !important;;
}

a, a:hover {
  text-decoration: none !important;
}

.form-group input, .admin-header-wrapper input {
    padding-bottom: 10px !important;
    font-size: 14px !important;
    padding-left: 0px !important;
    border-radius: 0px !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid #777777 !important;
}

input.is-error, textarea.is-error, .basic-multi-select.is-error .select__control {
  border-bottom: 2px solid red !important;
}

form {
  width:100% !important;
}

input:focus, textarea:focus {
    box-shadow:none !important;
    outline:0 !important;
}

/* PRZYCISKI APLIKACJI */
.btn-primary {
    color: #fff;
    background-color: #68bb73;
    border-color: #68bb73;
}

.btn-primary.focus, .btn-primary:focus, .btn-primary.hover, .btn-primary:hover{
  box-shadow:none !important;
  outline:0 !important;
  color: #fff !important;
  background-color: #a6daa4 !important;
  border-color: #a6daa4 !important;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #27dc16;
  background-color: #27dc16;
}

.admin-right-wrapper{
  margin-top: 80px;
  margin-left: 250px;
}

.admin-right-wrapper.no-auth{
  margin-top: 0px;
  margin-left: 0px;
}

/* LOADER */
.absolute-loader {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  background: #efefef;
  z-index: 1000 !important;
}

.app-loader{
  z-index:200;
}

.powered-by {
  position: absolute;
  right: 60px;
  bottom: 60px;
}

/* EDIT FORM SECTION */
.save-wrapper{
  height:40px;
  margin-bottom:40px;
}

.save-wrapper .save-button .btn {
  height:40px;
  padding-left:24px;
  padding-right:24px;
}

/* radio buttons */
.custom-radio {
  margin-top: 5px;
  margin-right: 10px;
}

/* no element  */
.no-element{
  text-transform: uppercase;
  font-size: 15px;
  color: #151515;
  font-weight: 300;
  letter-spacing: 4px;
}

/* pagination */

.pagination-wrapper{
  margin-bottom: 24px;
  margin-top: 24px;
  width: 100%;
  display: flex;
}

.pagination-wrapper .pagination-navigation{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pagination-wrapper .pag-go-prev, .pagination-wrapper .pag-go-next{
  width: 25px;
  height: 25px;
  background: #68bb73;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}

.pagination-wrapper .pag-go-prev svg {
  margin-right: 2px;
}

.pagination-wrapper .pag-go-next svg {
  margin-left: 2px;
}

.pagination-wrapper .pagination-routes, .routes{
  display: flex;
  flex-direction: row;
}

.pagination-wrapper .single-route{
  font-size: 13px;
  font-weight: 500;
  margin-left: 7px;
  margin-right: 7px;
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}

.pagination-wrapper .single-route:hover{
  border: 2px solid #11711e;
}

.pagination-wrapper .routes .single-route{
  margin-left: 7px;
  margin-right: 7px;
}

.pagination-wrapper .route-breaker{
  margin-left: 7px;
  margin-right: 7px;
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  justify-content: center;
  align-items: flex-end;
}

.pagination-wrapper .single-route.active{
  border: 2px solid #68bb73;
}

.pagination-wrapper a{
  color: #1d1d1d;
  text-decoration: none;
  background-color: transparent;
}

.error-warpper{
  color: red;
  margin-top: 7px;
  font-size: 15px;
}

/* datepicker */
.react-datetime-picker__wrapper{
  width:200px;
}

/* TOAST */

.toast-wrapper {
  bottom: 0;
  left: 0;
  position: fixed;
  padding: 15px 45px 15px 45px;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
  width: 100%;
  background: #b3b3b3d9;
}

.app-toast {
  padding: 15px 45px 15px 45px;
}

.app-toast span {
  font-size: 15px;
  font-weight: 400;
}

.app-toast.info-toast {
  background: #1d1d1d;
}

.app-toast.alert-toast {
  background: #ca3030;
}

/* sort view */
.sort-wrapper{
  position: absolute;
  margin-top: 11px;
  width: 300px;
}

.sort-wrapper .select__control {
  background-color:#f3f6ff00 !important;
  box-shadow:none !important;
}

/* select user */
.select-user-wrapper{
  position: absolute;
  margin-top: 11px;
  width: 300px;
  margin-left: 316px;
}
.select-user-wrapper .select__control {
  background-color:#f3f6ff00 !important;
  box-shadow:none !important;
}