.notification-wrapper {
    margin:24px;
    padding:24px;
    background:white;
    border-right: 3px solid #68bb73;
}

.select__control{
    border: none !important;
    border-bottom: 1px solid grey !important;
    border-radius: 0 !important;
    box-shadow: 1px 1px 1px 1px white !important;
}

.select__value-container{
    padding-left: 0px !important;
}

.select__input input {
    padding-bottom: 0px !important;
}

.basic-multi-select {
    margin-top:5px;
}
